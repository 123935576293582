import PaypalButton from './paypal_button';
import $ from 'jquery';
import  { t as translate } from './utility.js';
import newRollbar from './rollbar';

export default class PaypalExpressCheckoutButton extends PaypalButton {
  constructor(buttonDivId) {
    super();
    this.params = $(buttonDivId).data();
    paypal.Button.render({
      env: this.params["environment"], // sandbox | production
      onAuthorize: this.onAuthorize.bind(this),
      onCancel: this.onCancel.bind(this),
      onError: this.onError.bind(this),
      payment: this.payment.bind(this),
      style: {
        label: 'pay',
        tagline: false,
        shape: 'rect',
        size: 'medium'
      }

    }, buttonDivId);
  }

  onAuthorize(data, actions) {
    this.disablePayPalButton();
    var self = this;
    // Get csrf token

    var csrf_token = $( 'meta[name="csrf-token"]' ).attr( 'content' );

    // Make a call to the txt2give server to execute the payment

    return paypal.request.post(this.params["executeUrl"],
        data,
        { headers: {'X-CSRF-Token': csrf_token } }
        ).then((res) => {
      window.location = self.params["doneUrl"];
    }).catch((e) => {
      self.onError(e);
      actions.close();
    });
  }

  onError(err) {
    newRollbar.error(err);
    this.displayFlashMessage(translate('errors.messages.something_went_wrong'),
                             'danger');
  }

  // Wait for the PayPal button to be clicked
  payment(resolve, reject) {
    var data = {};

    // Get csrf token

    var csrf_token = $('meta[name="csrf-token"]').attr( 'content' );

    // Make a call to the txt2give server to set up the payment

    return paypal.request.post(this.params["createUrl"],
        data,
        { headers: {'X-CSRF-Token': csrf_token } }
        ).then((res) => {
      return res.payToken;
    }).catch((e) => {
      reject(
        new Error("Error occurred while contacting server to create payment: " + e.message)
      );
    });
  }
}
