import  { t as translate } from './utility.js';
import newRollbar from './rollbar';

export default class PaypalButton {

  disablePayPalButton() {
    $('.paypal-button')[0].style.pointerEvents = 'none';
  }

  displayFlashMessage(message, type) {
    var errorParent = $("div.center-block");
    var el =
      jQuery.parseHTML("<section class='alert alert-dismissable alert-"
          + type + "'><div>"
          + message
                       + "</div></section>");
      errorParent.prepend(el);
  }

  onCancel(data, actions) {
    newRollbar.info({ message: 'PayPal authorization cancelled'}, data );
    this.displayFlashMessage(
        translate('notifications.paypal_authorization_canceled'),
      'info');
  }


}
